
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import VueTurnstile from "vue-turnstile";

export default {
  components: { VueTurnstile },
  setup() {
    const email = ref("");
    const token = ref("");
    const results = ref([]);
    const isFetching = ref(false);
    const isCaptchaSolved = ref(false);
    const searchComplete = ref(false);    
    const route = useRoute();
    const router = useRouter();
    const hash = route.params.hash || "";    

    onMounted(async() => {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/orders/validate_hash/shipping_by_customer`,
          {
            hash
          }
        );
        document.documentElement.style.setProperty('--button-search-bg-color', response.data.button_search_bkg);
        document.documentElement.style.setProperty('--button-search-color', response.data.button_search_color);
        document.documentElement.style.setProperty('--table-results-head-bg-color', response.data.table_head_bkg);
        document.documentElement.style.setProperty('--table-results-head-color', response.data.table_head_color);        
      } catch (error) {
        router.push({ name: "404" });        
      } finally {        
      }
    });

    // Validar email
    const isEmailValid = computed(() =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)
    );

    // Condición para habilitar la búsqueda
    // const canSearch = computed(() => isEmailValid.value && isCaptchaSolved.value);
    const canSearch = computed(() => isEmailValid.value);

    const onCaptchaSuccess = (captchaToken) => {
      alert('Captcha resuelto');
      token.value = captchaToken;
      isCaptchaSolved.value = true;
    };

    const onCaptchaError = () => {
      isCaptchaSolved.value = false;
      token.value = "";
    };

    const fetchData = async () => {
      if (!canSearch.value) {
        return;
      }
      isFetching.value = true;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/orders/shipping_by_customer`,
          {
            email: email.value,
            hash,
            recaptcha: token.value,
          }
        );
        results.value = response.data.orders || [];
      } catch (error) {
        console.error("Error al buscar datos:", error);
        // router.push({ name: "404" });
        results.value = [];
      } finally {
        isFetching.value = false;
        searchComplete.value = true;
      }
    };

    const resetSearch = () => {
      email.value = "";
      token.value = "";
      results.value = [];
      isCaptchaSolved.value = false;
      searchComplete.value = false;
      const turnstile = document.querySelector("[data-recaptcha]");
      if (turnstile) {
        //turnstile.reset();
      }
    };

    return {
      email,
      token,
      hash,
      results,
      isFetching,
      isEmailValid,
      canSearch,
      fetchData,
      resetSearch,
      searchComplete,
      onCaptchaSuccess,
      onCaptchaError,
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    formatCurrency(amount) {
      const number = parseInt(amount, 10); // Asegurarse de que sea un número entero
      return `$${number.toLocaleString('es-CL')}`;
    }
  }
};
